import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "./src/theme"
// gatsby-browser.js に以下を追加
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {element}
  </ThemeProvider>
)
